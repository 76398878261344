import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { menuItems } from "../../../data/menudata";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

const isObject = function (a) {
  return !!a && a.constructor === Object;
};

const Menu = ({ innerPage = false, handleMenuClick, ...rest }) => {
  const { languages, originalPath } = useI18next();

  const scrollToElement = (id, e) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Navbar.Collapse id="responsive-navbar-nav" {...rest}>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="d-lg-none offcanvas-close"
        />
        <Nav className="mr-auto site-menu-main">
          {menuItems.map(
            (
              {
                label,
                isExternal = false,
                name,
                items,
                megamenu,
                col,
                ...rest
              },
              index
            ) => {
              const hasSubItems = Array.isArray(items);
              const hasMegaMenu = isObject(items);

              return (
                <React.Fragment key={name + index}>
                  <>
                    {innerPage ? (
                      <Nav.Item className="main-nav-item">
                        <Nav.Link
                          className="nav-link"
                          href={`/#${name}`}
                          onClick={handleMenuClick}
                        >
                          <Trans>{label}</Trans>
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      <Nav.Item className="main-nav-item">
                        <a
                          href={`#${name}`}
                          className="nav-link"
                          onClick={(e) => {
                            handleMenuClick();
                            scrollToElement(name, e);
                          }}
                        >
                          <Trans>{label}</Trans>
                        </a>
                      </Nav.Item>
                    )}
                  </>
                </React.Fragment>
              );
            }
          )}
        </Nav>
      </Navbar.Collapse>
      <ul className="languages">
        {languages.map((lng) => (
          <li key={lng}>
            <Link to={originalPath} language={lng}>
              {lng}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Menu;
