import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import siteBrandDark from "~image/logo/logo-black.png";
import siteBrandLight from "~image/logo/logo-white.png";
import Menu from "./Menu";

const SiteNavbar = ({
  buttonBlock,
  darkLogo,
  customLogo,
  defaultLogo,
  innerPage = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded(!expanded);
  const handleMenuClick = () => setExpanded(false);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="site-navbar"
        expanded={expanded}
      >
        <Navbar.Brand href="/">
          {defaultLogo ? (
            <img
              src={defaultLogo}
              alt="site-brand"
              className="site-brand-logo"
            />
          ) : customLogo ? (
            <img src={customLogo} alt="site-brand" />
          ) : (
            <img
              src={darkLogo ? siteBrandDark : siteBrandLight}
              alt="site-brand"
            />
          )}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="hamburgur"
          onClick={handleToggle}
        />
        <Menu innerPage={innerPage} handleMenuClick={handleMenuClick} />
        {buttonBlock}
      </Navbar>
    </>
  );
};

export default SiteNavbar;
