export const menuItems = [
  {
    name: "our-offers",
    label: "Our Offers",
    isExternal: false,
  },
  // {
  //   name: "how-we-work",
  //   label: "How We Work",
  //   isExternal: false,
  // },
  {
    name: "faq",
    label: "FAQ",
    isExternal: false,
  },
  {
    name: "contact",
    label: "Contact",
    isExternal: false,
  },
];
export default menuItems;
