import React from "react";
import { Helmet } from "react-helmet";
import favicon from "~image/favicon.ico";
import Header from "../Header";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";

export default function Layout({ children, innerPage = false }) {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const siteUrl = data.site.siteMetadata.siteUrl;
  const siteTitle = t("Legal jobs in Europe");
  const jsonLD = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Lucru legal în Europa - JobExplore.EU",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "str. Ismail 31, of. 16",
      "addressLocality": "Chișinău",
      "postalCode": "MD-2001",
      "addressCountry": "Republica Moldova",
    },
    "telephone": "+373 62 000050",
    "url": "https://jobexplore.eu/",
    "sameAs": [
      "https://www.facebook.com/lucruineurope.md",
      "https://www.instagram.com/lucruineuropa.md/",
    ],
    "description": "Locuri de muncă în Europa. Pentru deținătorii pașaportului european și cel biometric.",
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "47.015445332573385",
      "longitude": "28.84414653789337",
    },
    "openingHours": ["Mo-Fr 9:00-16:00", "Sa 9:00-12:00"],
  };

  return (
    <>
      <Helmet>
        <title>{siteTitle}</title>

        {/* Inject JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify(jsonLD)}
        </script>

        <meta property="og:type" content="website" />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:description" content={t("Legal jobs in Europe")} />
        <meta property="og:image" content={siteUrl + "/og-image.png"} />
        <meta property="og:site_name" content="JobExplore.EU" />

        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <Header innerPage={innerPage} />
      {children}
    </>
  );
}
